import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { regenerateOnRetry, RetryWithEscalation } from '@terminus-lib/fe-jwt';

import { EnvService } from '@shared/environment';

import * as AppSettingsSelectors from '../../state/app-settings.selectors';
import { AppSettingsState } from '../../state/app-settings.reducer';
import { IPlatformBrowserIdentity } from '../../interfaces/platform-browser-identity.interface';
import { PlatformBrowserIdentitySource } from '../../sources/platform-browser-identity/platform-browser-identity.source';
import { IPlatformBrowserIdentityDecorateRequestParam } from '../../interfaces/platform-browser-identity-params.interface';

@Injectable({
  providedIn: 'root',
})
export class PlatformBrowserIdentityService {
  constructor(
    public store: Store<AppSettingsState>,
    public source: PlatformBrowserIdentitySource,
    public retry: RetryWithEscalation,
    public envService: EnvService
  ) {
    source.jwt = this.store.pipe(
      select(AppSettingsSelectors.getPlatformBrowserIdentity)
    );
  }

  getUserBrowserIdentity$(): Observable<IPlatformBrowserIdentity> {
    return regenerateOnRetry(() => this.source.getUserBrowserIdentity$());
  }

  getUserBrowserIdentityDecorate$(params: IPlatformBrowserIdentityDecorateRequestParam): Observable<null> {
    const TERM_UL_OPT_COOKIE = 'tpd_ewHnG6Gu';
    return regenerateOnRetry(() => this.source.getUserBrowserIdentityDecorate$(params)).pipe(
      this.retry.retryWithEscalation(TERM_UL_OPT_COOKIE),
    );
  }
}
