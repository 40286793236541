import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { InitialTokenExtracted, JwtTokenManagementActionTypes } from '@terminus-lib/fe-jwt';

import { IDcLastUpdatedDate, userMessageFactory } from '@shared/interfaces';
import { AppSettingsService } from '../services/app-settings/app-settings.service';
import { IPlatformBrowserIdentity } from '../interfaces/platform-browser-identity.interface';
import { PlatformBrowserIdentityService } from '../services/platform-browser-identity/platform-browser-identity.service';
import { IPlatformBrowserIdentityDecorateRequestParam } from '../interfaces/platform-browser-identity-params.interface';
import { AppSettingsState } from './app-settings.reducer';
import { appSettingsActions } from './app-settings.actions';
import * as AppSettingsSelectors from './app-settings.selectors';
import { notificationMessagesActions } from '@notification-messages';
import { selectUserProfile } from '@shared/data-access/user-common';
import { userActions } from '@user/user.store';
import { Router } from '@angular/router';
import { EnvService } from '@shared/environment';

@Injectable()
export class AppSettingsEffects {
  constructor(
    private actions$: Actions,
    private appSettingsService: AppSettingsService,
    private platformBrowserIdentityService: PlatformBrowserIdentityService,
    private store: Store<AppSettingsState>,
    private readonly router: Router,
    private readonly envService: EnvService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appSettingsActions.loadDcLastUpdatedDate),
      switchMap(() =>
        this.appSettingsService.getDcLastUpdatedDate$().pipe(
          map((lastUpdated: IDcLastUpdatedDate) =>
            appSettingsActions.loadDcLastUpdatedDateSuccess({
              date: new Date(lastUpdated.lastUpdate),
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              appSettingsActions.loadDcLastUpdatedDateFailure({
                message: `${error.status}: Unable to retrieve last data cycle date.  Please try again later.`,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appSettingsActions.loadDcLastUpdatedDateFailure),
      map(action => notificationMessagesActions.addMessage({ message: userMessageFactory({ n: action.message }) })),
    ),
  );

  setPlatformBrowserIdentityOnTokenExtract$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitialTokenExtracted>(JwtTokenManagementActionTypes.InitialTokenExtracted),
      mergeMap(() =>
        this.platformBrowserIdentityService
          .getUserBrowserIdentity$()
          .pipe(
            map((platformBrowserIdentity: IPlatformBrowserIdentity) =>
              appSettingsActions.setPlatformBrowserIdentity({ identity: platformBrowserIdentity }),
            ),
          ),
      ),
    ),
  );

  decoratePlatformBrowserIdentity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appSettingsActions.platformBrowserIdentityDecorate),
        withLatestFrom(
          this.store.pipe(select(selectUserProfile)),
          this.store.pipe(select(AppSettingsSelectors.getPlatformBrowserIdentity)),
        ),
        filter(([, userProfile, platformBrowserIdentity]) => userProfile?.orgUuid && !!platformBrowserIdentity),
        switchMap(([, userProfile]) => {
          const platformBrowserIdentityDecorateRequestParam: IPlatformBrowserIdentityDecorateRequestParam = {
            userHubUuid: userProfile.orgUuid,
          };
          return this.platformBrowserIdentityService.getUserBrowserIdentityDecorate$(
            platformBrowserIdentityDecorateRequestParam,
          );
        }),
      ),
    { dispatch: false },
  );

  public readonly browserIdentitySet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appSettingsActions.setPlatformBrowserIdentity),
      mergeMap(_ => {
        const envs = this.envService.getEnv();
        const isFirebaseHost = /web.app/.exec(location.host);
        if (!envs.SPLITIO_API_KEY || envs.ALLOW_EMPLOYEE_LOGIN_PAGE || isFirebaseHost) {
          return [userActions.setUnifiedLoginStatus({ status: false })];
        } else {
          const NoAuthRequiredPath = ['panic', 'page-not-found'];
          const currentUrl = this.router.url;
          if (!NoAuthRequiredPath.some(path => currentUrl.includes(path))) {
            return [
              userActions.setUnifiedLoginStatus({ status: true }),
              userActions.setUserZendeskSsoAccess({ access: true }),
            ];
          }
        }
      }),
    ),
  );
}
