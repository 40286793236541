import { appSettingsActions } from './app-settings.actions';
import { IPlatformBrowserIdentity } from '../interfaces/platform-browser-identity.interface';
import { Action, createReducer, on } from '@ngrx/store';

export interface AppSettingsState {
  dcLastUpdatedDate: Date;
  platformBrowserIdentity: IPlatformBrowserIdentity;
  splitIOSDKLoaded: boolean;
}

export const initialState: AppSettingsState = {
  dcLastUpdatedDate: null,
  platformBrowserIdentity: null,
  splitIOSDKLoaded: false,
};

const appSettingsReducer = createReducer(
  initialState,
  on(
    appSettingsActions.loadDcLastUpdatedDate,
    (state) => state
  ),
  on(
    appSettingsActions.loadDcLastUpdatedDateSuccess,
    (state, action) => ({
      ...state,
      dcLastUpdatedDate: action.date
    })
  ),
  on(
    appSettingsActions.loadDcLastUpdatedDateFailure,
    (state) => state
  ),
  on(
    appSettingsActions.setPlatformBrowserIdentity,
    (state, action) => ({
      ...state,
      platformBrowserIdentity: action.identity,
    })
  )
);

export function reducer(state: AppSettingsState | undefined, action: Action): AppSettingsState {
  return appSettingsReducer(state, action);
}
