import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnalyticsTotalsService } from '../../services/analytics-totals.service';
import * as interfaces from '../../interfaces';
import { totalsActions, TotalsLoadActions } from './totals.actions';
import * as generalSelectors from '../general/general.selectors';
import { generalActions } from '../general/general.actions';
import * as GlobalFiltersStore from '@shared/data-access/global-filters';
import { notificationMessagesActions } from '@notification-messages';
import { IAnalyticsGlobalFilters, userMessageFactory } from '@shared/interfaces';
import { IAnalyticsRequest } from '@measurement-studio/interfaces';

type LatestFilters<A extends Action> = [
  A,
  IAnalyticsRequest,
  IAnalyticsGlobalFilters,
  string,
];

@Injectable()
export class TotalsEffects {
  constructor(private analyticsTotalsService: AnalyticsTotalsService,
              private store: Store<unknown>,
              private actions$: Actions) {
  }

  public loadMetaDataTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadMetaDataTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsMetaDataTotal$(request).pipe(
        map((data: interfaces.ICampaignMetaDataTotals) =>
          totalsActions.loadMetaDataTotalSuccess({data: data.campaign, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadMetaDataTotal';
          return of(totalsActions.loadMetaDataTotalFailure({message}));
        })
      );
    })
  ));

  public loadResponsesTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadResponsesTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsResponsesTotal$(request).pipe(
        map((data: interfaces.ICampaignResponsesTotals) =>
          totalsActions.loadResponsesTotalSuccess({data: data.response, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadResponsesTotal';
          return of(totalsActions.loadResponsesTotalFailure({message}));
        })
      );
    })
  ));

  public loadAttributionTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadAttributionTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsAttributionTotal$(request).pipe(
        map((data: interfaces.ICampaignAttributionTotals) =>
          totalsActions.loadAttributionTotalSuccess({data: data.attributed, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadAttributionTotal';
          return of(totalsActions.loadAttributionTotalFailure({message}));
        })
      );
    })
  ));

  public loadInfluenceTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadInfluenceTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsInfluenceTotal$(request).pipe(
        map((data: interfaces.ICampaignInfluenceTotals) =>
          totalsActions.loadInfluenceTotalSuccess({data: data.influenced, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadInfluenceTotal';
          return of(totalsActions.loadInfluenceTotalFailure({message}));
        })
      );
    })
  ));

  public loadReturnsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadReturnsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsReturnsTotal$(request).pipe(
        map((data: interfaces.ICampaignReturnsTotals) =>
          totalsActions.loadReturnsTotalSuccess({data: data.returns, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadReturnsTotal';
          return of(totalsActions.loadReturnsTotalFailure({message}));
        })
      );
    })
  ));

  public loadCostsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadCostsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getCampaignAnalyticsCostsTotal$(request).pipe(
        map((data: interfaces.ICampaignCostsTotals) =>
          totalsActions.loadCostsTotalSuccess({data: data.costs, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.campaignAnalytics.errors.loadCostsTotal';
          return of(totalsActions.loadCostsTotalFailure({message}));
        })
      );
    })
  ));

  public loadWebActivitiesResponsesTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadWebActivitiesResponsesTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getWebActivitiesResponsesTotal$(request).pipe(
        map((data: interfaces.ICampaignResponsesTotals) =>
          totalsActions.loadWebActivitiesResponsesTotalSuccess({data: data.response, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadResponsesTotal';
          return of(totalsActions.loadWebActivitiesResponsesTotalFailure({message}));
        })
      );
    })
  ));

  public loadWebActivitiesAttributionTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadWebActivitiesAttributionTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getWebActivitiesAttributionTotal$(request).pipe(
        map((data: interfaces.ICampaignAttributionTotals) =>
          totalsActions.loadWebActivitiesAttributionTotalSuccess({data: data.attributed, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadAttributionTotal';
          return of(totalsActions.loadWebActivitiesAttributionTotalFailure({message}));
        })
      );
    })
  ));

  public loadWebActivitiesInfluenceTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadWebActivitiesInfluenceTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getWebActivitiesInfluenceTotal$(request).pipe(
        map((data: interfaces.ICampaignInfluenceTotals) =>
          totalsActions.loadWebActivitiesInfluenceTotalSuccess({data: data.influenced, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadInfluenceTotal';
          return of(totalsActions.loadWebActivitiesInfluenceTotalFailure({message}));
        })
      );
    })
  ));

  public loadWebActivitiesReturnsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadWebActivitiesReturnsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getWebActivitiesReturnsTotal$(request).pipe(
        map((data: interfaces.ICampaignReturnsTotals) =>
          totalsActions.loadWebActivitiesReturnsTotalSuccess({data: data.returns, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadReturnsTotal';
          return of(totalsActions.loadWebActivitiesReturnsTotalFailure({message}));
        })
      );
    })
  ));

  public loadWebActivitiesCostsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadWebActivitiesCostsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getWebActivitiesCostsTotal$(request).pipe(
        map((data: interfaces.ICampaignCostsTotals) =>
          totalsActions.loadWebActivitiesCostsTotalSuccess({data: data.costs, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadCostsTotal';
          return of(totalsActions.loadWebActivitiesCostsTotalFailure({message}));
        })
      );
    })
  ));

  public loadSourceResponsesTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadSourceResponsesTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getSourceResponsesTotal$(request).pipe(
        map((data: interfaces.ICampaignResponsesTotals) =>
          totalsActions.loadSourceResponsesTotalSuccess({data: data.response, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadSourceResponsesTotal';
          return of(totalsActions.loadSourceResponsesTotalFailure({message}));
        })
      );
    })
  ));

  public loadSourceAttributionTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadSourceAttributionTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getSourceAttributionTotal$(request).pipe(
        map((data: interfaces.ICampaignAttributionTotals) =>
          totalsActions.loadSourceAttributionTotalSuccess({data: data.attributed, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadSourceAttributionTotal';
          return of(totalsActions.loadSourceAttributionTotalFailure({message}));
        })
      );
    })
  ));

  public loadSourceInfluenceTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadSourceInfluenceTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getSourceInfluenceTotal$(request).pipe(
        map((data: interfaces.ICampaignInfluenceTotals) =>
          totalsActions.loadSourceInfluenceTotalSuccess({data: data.influenced, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadSourceInfluenceTotal';
          return of(totalsActions.loadSourceInfluenceTotalFailure({message}));
        })
      );
    })
  ));

  public loadSourceReturnsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadSourceReturnsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getSourceReturnsTotal$(request).pipe(
        map((data: interfaces.ICampaignReturnsTotals) =>
          totalsActions.loadSourceReturnsTotalSuccess({data: data.returns, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadSourceReturnsTotal';
          return of(totalsActions.loadSourceReturnsTotalFailure({message}));
        })
      );
    })
  ));

  public loadSourceCostsTotal$ = createEffect(() => this.actions$.pipe(
    ofType(totalsActions.loadSourceCostsTotal),
    concatMap(this.withLatestFilters.bind(this)), // use concatMap to wait until dispatching this action
    map(this.mapToActionAndRequest),
    mergeMap(([action, request]) => {
      return this.analyticsTotalsService.getSourceCostsTotal$(request).pipe(
        map((data: interfaces.ICampaignCostsTotals) => totalsActions.loadSourceCostsTotalSuccess({data: data.costs, key: action.payload})),
        catchError((error: HttpErrorResponse) => {
          const message = error.message || 'measurementStudio.features.analytics.webActivities.errors.loadSourceCostsTotal';
          return of(totalsActions.loadSourceCostsTotalFailure({message}));
        })
      );
    })
  ));

  public onFailure$ = createEffect(() => this.actions$.pipe(
    ofType(
      totalsActions.loadMetaDataTotalFailure,
      totalsActions.loadResponsesTotalFailure,
      totalsActions.loadAttributionTotalFailure,
      totalsActions.loadInfluenceTotalFailure,
      totalsActions.loadReturnsTotalFailure,
      totalsActions.loadCostsTotalFailure,
      totalsActions.loadWebActivitiesResponsesTotalFailure,
      totalsActions.loadWebActivitiesAttributionTotalFailure,
      totalsActions.loadWebActivitiesInfluenceTotalFailure,
      totalsActions.loadWebActivitiesReturnsTotalFailure,
      totalsActions.loadWebActivitiesCostsTotalFailure,
      totalsActions.loadSourceResponsesTotalFailure,
      totalsActions.loadSourceAttributionTotalFailure,
      totalsActions.loadSourceInfluenceTotalFailure,
      totalsActions.loadSourceReturnsTotalFailure,
      totalsActions.loadSourceCostsTotalFailure,
    ),
    map((action) =>
      notificationMessagesActions.addMessage({ message: userMessageFactory({n: action.message}) }))
  ));

  public onChangeFiltersState$ = createEffect(() => this.actions$.pipe(
    ofType(
      generalActions.setFilters,
      generalActions.changeCampaignGroupsSearch,
      generalActions.changeCampaignsSearch,
      generalActions.changeCampaignSearch,
      generalActions.changeSourceSearch,
      generalActions.changeMediumsSearch,
    ),
    map(() => totalsActions.resetTotalData()),
  ));

  private withLatestFilters<T extends TotalsLoadActions>(action: T): Observable<LatestFilters<T>> {
    return of(action).pipe(
      withLatestFrom(
        this.store.pipe(select(generalSelectors.getFilters)),
        this.store.pipe(select(GlobalFiltersStore.getAnalyticsGlobalFilters)),
        this.getSearchState(action),
      ),
    );
  }

  private mapToActionAndRequest<T extends TotalsLoadActions>([action, filters, globalFilters, search]: LatestFilters<T>):
    [T, IAnalyticsRequest] {
    const hasTypeFilter: interfaces.LoadTotalsPayload[] =
      [interfaces.TotalsSlice.Campaigns, interfaces.TotalsSlice.Campaign, interfaces.TotalsSlice.Source];
    return [action, {
      ...filters,
      globalFilters,
      searchParam: search,
      typeFilter: hasTypeFilter.includes(action.payload) && filters.typeFilter ? filters.typeFilter : '',
      crossTypeFilter: interfaces.totalsTableKeys.includes(action.payload) ? '' : action.payload.toString(),
      isDriver: false,
    }];
  }

  private getSearchState(action: TotalsLoadActions): Observable<string> {
    switch (action.payload) {
      case 'source':
        return this.store.pipe(select(generalSelectors.getSourceSearch));
      case 'campaigns':
        return this.store.pipe(select(generalSelectors.getCampaignsSearch));
      case 'campaignGroups':
        return this.store.pipe(select(generalSelectors.getCampaignGroupsSearch));
      case 'campaign':
        return this.store.pipe(select(generalSelectors.getCampaignSearch));
      case 'mediums':
        return this.store.pipe(select(generalSelectors.getMediumsSearch));
      default:
        return of('');
    }
  }
}
