export enum TreatmentTypes {
  user = 'cust-engage-mar',
  marketer = 'user-engage',
  browser = 'user-browser',
  studiosOrgUuid = 'cust-hub-org',
  userPlatform = 'user-platform',
}

export const groupedTreatmentTokens = {
  [TreatmentTypes.user]: {
    event_centric_marketer_conversions_report: 'event_centric_marketer_conversions_report',
  },
  [TreatmentTypes.userPlatform]: {
    retargeting_account_table_platform_user: 'retargeting_account_table_platform_user',
  },
  [TreatmentTypes.browser]: {
    unified_login: 'unified_login',
    zd_sso: 'zd_sso',
  },
  [TreatmentTypes.studiosOrgUuid]: {
    hubajs_migration: 'hubajs_migration',
    abm_scorecard_segments: 'abm_scorecard_segments',
    ds_account_hub_upload: 'ds_account_hub_upload',
  },
  [TreatmentTypes.marketer]: {
    cal_assets_for_pretargeting: 'cal_assets_for_pretargeting',
    legacy_display_ads_access: 'legacy_display_ads_access',
    tpe_warning_legacy: 'tpe_warning_legacy',
    mcb_ads_2024: 'mcb_ads_2024',
  },
};

export type GroupedTreatmentTokens = typeof groupedTreatmentTokens;

export interface TokenStateChange {
  token: string;
  enabled: boolean;
  control?: boolean;
}

export type TreatmentToken =
  | keyof GroupedTreatmentTokens[TreatmentTypes.user]
  | keyof GroupedTreatmentTokens[TreatmentTypes.browser]
  | keyof GroupedTreatmentTokens[TreatmentTypes.marketer]
  | keyof GroupedTreatmentTokens[TreatmentTypes.userPlatform]
  | keyof GroupedTreatmentTokens[TreatmentTypes.studiosOrgUuid];

export const treatmentTokens: Record<TreatmentToken, TreatmentToken> = Object.keys(groupedTreatmentTokens).reduce(
  (acc, treatmentType) => ({
    ...acc,
    ...groupedTreatmentTokens[treatmentType],
  }),
  {} as Record<TreatmentToken, TreatmentToken>,
);

export function getTokensForTreatment<T extends keyof GroupedTreatmentTokens[O], O extends TreatmentTypes>(
  treatmentType: TreatmentTypes,
): T[] {
  return Object.keys(groupedTreatmentTokens[treatmentType]) as T[];
}
