import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpClientBase } from '@shared/http-client-base';
import { IPlatformBrowserIdentity } from '../../interfaces/platform-browser-identity.interface';
import { IPlatformBrowserIdentityDecorateRequestParam } from '../../interfaces/platform-browser-identity-params.interface';

export enum PlatformBrowserIdentitySourceURLs {
  GetUserBrowserIdentity = '/v1/user_browser_identify',
  getUserBrowserIdentityDecorate = '/v1/user_browser_identity_decorate',
}

@Injectable({
  providedIn: 'root',
})
export class PlatformBrowserIdentitySource extends HttpClientBase {
  getUserBrowserIdentity$(): Observable<IPlatformBrowserIdentity> {
    const endpoint = `${this.envService.getEnv().PLATFORM_BROWSER_IDENTITY_URL}${PlatformBrowserIdentitySourceURLs.GetUserBrowserIdentity}`;

    return this.httpClient.post<IPlatformBrowserIdentity>(endpoint, {}, {
      headers: this.addAuthorizationHeader(),
      withCredentials: true
    });
  }

  getUserBrowserIdentityDecorate$(params: IPlatformBrowserIdentityDecorateRequestParam): Observable<null> {
    const endpoint =
      `${this.envService.getEnv().PLATFORM_BROWSER_IDENTITY_URL}${PlatformBrowserIdentitySourceURLs.getUserBrowserIdentityDecorate}`;

    return this.httpClient.post<null>(endpoint, params, {
      headers: this.addAuthorizationHeader(),
      withCredentials: true
    });
  }

  private addAuthorizationHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.customJWT}`);
  }
}
